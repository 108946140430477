// CONSTANTS

// LOCAL_URLs
// export const LOCAL_URL = "http://c24f-2400-adc5-184-8c00-74ea-59f2-9d2e-a88c.ngrok.io/retainoo";
// export const BASE_URL = "http://stage.retainoo.com:8080/retainoo";
// export const SIGNIN_PAGE = "http://localhost:3000/login";
// export const HOME_PAGE = "http://localhost:3000/";

// STAGING_URLs
export const BASE_URL = "http://stage.retainoo.com:8080/retainoo";
export const SIGNIN_PAGE = "http://retmarketplace-staging.s3-website-ap-southeast-2.amazonaws.com/login";
export const HOME_PAGE = "http://retmarketplace-staging.s3-website-ap-southeast-2.amazonaws.com/";

// // PRODUCTION_URLs
// export const BASE_URL = "https://portal.retainoo.com:8443/retainoo";
// // export const SIGNIN_PAGE = "https://retainoo.com/login";
// // export const HOME_PAGE = "https://retainoo.com/";
// export const SIGNIN_PAGE = "http://marketplace.retainoo.com/login";
// export const HOME_PAGE = "http://marketplace.retainoo.com/";

export const FEATURED_LIST_SIZE = 5;
export const SALONS_LIST_SIZE = 100;
